<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>ENTP</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            Sekitar 2-5% orang memiliki kepribadian ENTP di dunia. Dimana ENTP biasa disebut The visionaries atau penemu. Menurut dimensinya, ENTP merupakan pribadi yang Extroversion yakni memiliki hubungan dan jaringan luas dengan siapapun dan dimanapun. Intuition yakni mereka yang mendulukan hal abstrak dibandingkan hal yang konkrit. <br>
            Thinking yakni mereka yang menggunakan akal pikiran dan selalu memilih keputusan yang objektif. Serta Perception, dimana mereka akan menyesuaikan berbagai hal dan membuka segala sesuatu dengan orang lain tanpa memutuskan hal terlebih dahulu.
          </p>
          <p>
            Ciri kepribadian ENTP adalah:
            <ul>
              <li> Merasa senang ketika bisa membantu dan juga menunjukan semangat yang tinggi.</li>
              <li> Logis dan pemikir rasional serta fleksibel dan cerdas.</li>
              <li> Senang memperdebatkan suatu masalah dan membuktikannya, mampu bersikap fleksibel dan multi talenta.</li>
              <li> Seorang pemimpin yang baik dan tidak memaksa, serta menghargai pengetahuan serta kompetensi setiap orangnya.</li>
              <li> Seseorang yang senang bergerak dan juga aktif.</li>
            </ul>
          </p>
          <p>
            Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Jangan ingin menang sendiri dan cobalah lakukan win win solution.
              </li>
              <li>
                Hindari perdebatan tidak penting dan disiplinkan diri sendiri.
              </li>
              <li>
                Belajar untuk memberikan perhatian dan belajar untuk lebih waspada agar tidak terlalu optimis.
              </li>
              <li>
                Belajar untuk hadapi konflik dan permasalahan, jangan lari atau menutupi.
              </li>
              <li>
                Pekerjaan yang cocok adalah psikolog, pengacara, konsultan, insinyur, aktor, keilmuan, programer, pebisnis dan pemasaran.
              </li>
            </ul>
          </p>
          <p>
            Pasangan yang cocok untuk ENTP adalah mereka yang INTJ atau INFJP. Namun hal ini tidak bisa menghalangi anda untuk memilih pasangan yang cocok tanpa melihat MBTI.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/ENTP.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Extraversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="extraversion">Extraversion</h5>
              <p>cenderung memiliki jaringan sosial yang luas</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Intution.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="intution">Intution</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Thinking.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="thinking">Thinking</h5>
              <p>lebih fokus terhadap implikasi sosial dibandingkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Perceiving.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="perceiving">Perceiving</h5>
              <p>menyukai hal yang bersifat terograrnisir dan terstruktur</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ENTP",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'ENTP',
        slug: 'entp'
      }
    }
  }
};
</script>

<style scoped>

</style>
